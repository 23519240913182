import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';
import moment from "moment";
import cn from 'classnames';

import { ActionTypeOption, EmailTypeOption } from "../../ReactSelect/ReactSelectOption";
import { CustomizedTooltipDisabled, CustomizedTooltipFalsePositive } from "../../Tooltips/Tooltip";
import { AuthContext } from "../../../context/AuthContext";
import { Pagination } from "../../Pagination/Pagination";
import ReactSelect from "../../ReactSelect/ReactSelect";
import { useCrypto } from "../../../hooks/crypto.hook";
import { itemsOnPage } from "../../../config/config";
import { useHttp } from "../../../hooks/http.hook";
import { Loader } from "../../Loader/Loader";
import { ReactComponent as Description } from '../../../images/icons/description.svg';
import './UserActivity.css';

export const UserActivity = memo(({ userId, startDate, endDate }) => {
  const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const { request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token, user, showToastMessage } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();

  const [falsePositiveChangingLoading, setFalsePositiveChangingLoading] = useState(false);
  const [showOnlyUniqueEmails, setShowOnlyUniqueEmails] = useState(true);
  const [additionalLoading, setAdditionalLoading] = useState(false);
  const [emailTypesInitial, setEmailTypesInitial] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [selectedActionId, setSelectedActionId] = useState(0);
  const [mailingHistory, setMailingHistory] = useState([]);
  const [userActions, setUserActions] = useState({});
  const [actionTypes, setActionTypes] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [emailTypes, setEmailTypes] = useState([]);
  const [emailType, setEmailType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const fetchMailingHistory = useCallback(async () => {
    setAdditionalLoading(true);
    try {
      if (token) {
        setSelectedRow(-1);
        setDetailsVisible(false);

        const data = encryptData({
          userId,
          startDate,
          endDate,
          emailType: emailType?.value,
          actionType: actionType?.value,
          uniqueItems: showOnlyUniqueEmails,
          page,
        });
        const fetched = await requestWithSecondaryLoading('/back_office/api/analytics/user_analytic/activity', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptHistory = decryptData(fetched);

        setMailingHistory(decryptHistory?.rows);
        setUserActions(decryptHistory?.userActions);
        setCount(decryptHistory?.count);
        setActionTypes(decryptHistory?.actionTypes);
        setEmailTypes(decryptHistory?.emailTypes);

        !emailTypesInitial.length && setEmailTypesInitial(decryptHistory?.emailTypes);

        return decryptHistory;
      }
    } catch (error) {}
    finally {
      setTimeout(() => {
        setAdditionalLoading(false);
      }, 300)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailType, page, actionType, showOnlyUniqueEmails]);

  useEffect(() => {
    fetchMailingHistory();
  }, [fetchMailingHistory]);

  let preparedEmailTypesForOptionList = emailTypesInitial;
  if (actionType) preparedEmailTypesForOptionList = emailTypes;

  const emailTypesOptionList = preparedEmailTypesForOptionList?.map(type => (
    {
      value: type,
      label: type?.replace(/_/g, ' ')?.replace(/([A-Z])/g, ' $1').trim()?.replace(/\s+/g, ' ')?.replace(/^./, str => str.toUpperCase())
    }
  ));

  const actionTypesOptionList = actionTypes?.map(type => (
    {
      value: type,
      label: (" " + type)?.replace(/([A-Z])/g, ' $1').trim()?.replace(/^./, str => str.toUpperCase()),
    }
  ))

  const updateFalsePositiveActionSatus = async (selectedActionId, falsePositive) => {
    try {
      const data = encryptData({ actionId: selectedActionId, falsePositive });
      const updateStatus = await request(
        `/back_office/api/user/update_action_false_positive`,
        'POST',
        { data },
        { Authorization: `Bearer ${token}` }
      );

      return updateStatus;
    } catch (error) { }
  }

  const handleFalsePositiveCheckboxChange = async (selectedActionId, falsePositive, selectedAction) => {
    setFalsePositiveChangingLoading(true);
    try {
      const updateStatus = await updateFalsePositiveActionSatus(selectedActionId, falsePositive);
      showToastMessage(updateStatus.error, updateStatus.message);

      if (updateStatus.message) {
        setUserActions(prevActions => ({
          ...prevActions,
          [selectedAction]: prevActions[selectedAction].map(action => {
            if (action.id === selectedActionId) {
              return {
                ...action,
                falsePositive: !action.falsePositive
              };
            }
            return action;
          })
        }));
      }
    } catch (error) {
      showToastMessage(`Unable to mark action as ${falsePositive ? 'False Positive' : 'Not False Positive'}!`);
    } finally {
      setFalsePositiveChangingLoading(false);
      setSelectedActionId(0);
    }
  };

  const handleEmailChange = (data) => {
    setEmailType(data);
    setPage(1);
  };

  const handlePageChange = (page) => {
    setTimeout(() => {
      setPage(page);
    }, 100)
  };

  const handleActionChange = (data) => {
    setActionType(data);
    setPage(1);
  };

  const exportMailingCsv = async (userId, emailType, actionType, uniqueItems, startDate, endDate) => {
    try {
      if (token) {
        setDownloadLoading(true);

        const data = encryptData({
          userId,
          emailType,
          actionType,
          uniqueItems,
          startDate,
          endDate
        });
        const response = await fetch('/back_office/api/analytics/user_analytic/activity_csv', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (!response.ok) {
          showToastMessage('Failed to export CSV file!');
          setDownloadLoading(false);
          throw new Error('Download failed');
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        a.download = `mailing_and_actions_list__UserID-${userId}__`+ new Date().toISOString().split('T')[0] + '.csv';
        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with Mailing and Actions list downloaded successfully');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage('Failed to export CSV file!');
    }
  };

  return (
    <div>
      <div className='history-card-analytics'>
        <>
          <div className='user-analytics-table-container'>
            <h3>Mailing & Actions list</h3>
            <div className='analytics-select-container'>
              <div className='analytics-unique-emails'>
                <span>Unique emails:</span>
                <label
                  className={"slider-checkbox"}
                >
                  <input
                    type="checkbox"
                    className='checkbox-input'
                    checked={showOnlyUniqueEmails}
                    onChange={() => {
                      setPage(1);
                      setShowOnlyUniqueEmails(prev => !prev);
                    }}
                    disabled={additionalLoading}
                  />
                  <CustomizedTooltipFalsePositive
                    showFragment={true}
                    position={'top'}
                    text1={showOnlyUniqueEmails ? 'Only unique emails are displayed.' : null}
                    text2={showOnlyUniqueEmails ? 'Show all?' : 'Show only unique emails?'}
                    component={
                      <span className='slider' />
                    }
                  />
                </label>
              </div>
              <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--email-type', {
                'email-type-select--disabled': !emailTypesOptionList?.length
              })}>
                  <ReactSelect
                    options={emailTypesOptionList}
                    optionComponent={ EmailTypeOption }
                    placeholder={'Email Type...'}
                    value={emailType}
                    onChange={handleEmailChange}
                    isSearchable={true}
                    isClearable={true}
                    optionHeight={32}
                    rows={10}
                    isDisabled={!emailTypesOptionList?.length || additionalLoading}
                    isLoading={additionalLoading}
                  />

              </div>
              <div
                className={cn('input-container-select-analytics-item input-container-select-analytics-item--action-type',
                  {'action-type-select--disabled': !actionTypesOptionList.length}
                )}>
                <ReactSelect
                  options={actionTypesOptionList}
                  optionComponent={ActionTypeOption}
                  placeholder={'Action Type...'}
                  value={actionType}
                  onChange={handleActionChange}
                  isSearchable={true}
                  isClearable={true}
                  optionHeight={32}
                  rows={10}
                  isDisabled={!actionTypesOptionList.length || additionalLoading}
                  isLoading={additionalLoading}
                />
              </div>
              <div>
                {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 &&
                  <CustomizedTooltipDisabled
                    position={'left'}
                    text1={'This feature is available'}
                    text2={'in the Professional'}
                    text3={'and Enterprise plan'}
                  />
                }
                {mailingHistory.length
                  ? !downloadLoading
                    ? <button
                        onClick={() => exportMailingCsv(
                          userId, emailType?.value, actionType?.value, showOnlyUniqueEmails, startDate, endDate
                        )}
                        disabled={user.paymentPlanId !== 3 && user.paymentPlanId !== 4}
                      >
                        <Description />
                        Export CSV
                      </button>
                    : <button
                        className='csv-download-loading'
                        type="button"
                      >
                        <span className="mini-loader-csv"></span>
                      </button>
                  : <div style={{'width': '120px'}}></div>
                }
              </div>
            </div>
          </div>

          {!!mailingHistory.length
            ? !additionalLoading
              ? <table className="analytics-users-table">
                  <thead className="table-th-analytics">
                    <tr className="tr-grid-analytics-users tr-analytics">
                      <th>
                        Segment Name
                      </th>
                      <th>
                        Template Name
                      </th>
                      <th>
                        Template Subject
                      </th>
                      <th>
                        Email Type
                      </th>
                      <th>
                        Sending time
                      </th>
                      <th>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body table-body-analytics">
                    {mailingHistory?.map(item => {
                        const isVisible = selectedRow === item.id && detailsVisible;
                        return (
                          <tr
                            key={uuid()}
                            className={cn("tr-grid-analytics-users tr-analytics", {
                              "tr-grid-analytics-users--open": isVisible
                            })}
                            onClick={() => setSelectedRow(item.id)}
                            >
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>{item?.segmentName}</td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>{item?.template}</td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                {item?.mhSubject || item?.templateSubject || item?.subject}
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                {item?.name?.replace(/_/g, ' ')?.replace(/([A-Z])/g, ' $1').trim()?.replace(/\s+/g, ' ')?.replace(/^./, str => str.toUpperCase())}
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                <span>
                                  {moment(item?.sendouttimestamp).utc().format('MMM DD, YYYY')}<br />
                                  <small>at {moment(item?.sendouttimestamp).utc().format('hh:mm A')}</small>
                                </span>
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                {userActions?.[`${item?.segmentId}_${item?.emailTemplateId}`] &&
                                  <button
                                    type='button'
                                    className={cn('table-option-analytics', { 'table-option-analytics--active': isVisible })}
                                    onClick={() => {
                                      isVisible
                                        ? setDetailsVisible(!detailsVisible)
                                        : setDetailsVisible(true)
                                      setSelectedRow(item.id)
                                    }}
                                  >
                                    <span className={cn("analytics-list", {
                                      "analytics-active-list": isVisible
                                    })}>
                                      {isVisible ? 'Hide Actions' : 'View Actions'}
                                    </span>
                                  </button>
                                }
                              </td>
                            {isVisible && detailsVisible &&
                              <>
                                <td
                                  className={cn('analytics-title-td', {'analytics-title-td--open': selectedRow === item.id && detailsVisible})}
                                  onClick={() => {
                                    setDetailsVisible(!detailsVisible)
                                    setSelectedRow(-1)
                                  }}
                                >
                                  <span className='analytics-title'>User actions</span>
                                </td>
                                <td className={cn("analytics-users-table-additional", {
                                  "analytics-users-table-additional--open": selectedRow === item.id && detailsVisible
                                })}>
                                  <ul>
                                    <li className='analytics-li analytics-ul-title' key={user?.email}>
                                      <span>Action Type</span>
                                      <span>Created At</span>
                                      <span>False Positive</span>
                                    </li>
                                      {userActions?.[`${item?.segmentId}_${item?.emailTemplateId}`]?.map(action => {
                                        const falsePositiveButtonDisabled =
                                          (user.role !== 'owner' && user.role === 'tenant-admin' && !user?.tenants?.falsePositiveActionAccess) ||
                                          (user.role === 'tenant-admin' && !user?.tenants?.falsePositiveActionAccess) ||
                                          user.role === 'admin' ||
                                          falsePositiveChangingLoading ||
                                          moment(action?.createdAt).format('YYYY-MM-DD') < firstDayOfMonth

                                        let text1 = 'You do not have access to make these changes';
                                        let text2 = '';

                                        if (
                                          (user.role === 'owner' || (user.role === 'tenant-admin' && user?.tenants?.falsePositiveActionAccess))
                                            && moment(action?.createdAt).format('YYYY-MM-DD') >= firstDayOfMonth
                                          ) {
                                          text1 = 'Mark as';
                                          text2 = action?.falsePositive ? 'Not False Positive' : 'False Positive';
                                        } else if (
                                          (user.role === 'owner' || (user.role === 'tenant-admin' && user?.tenants?.falsePositiveActionAccess))
                                            && moment(action?.createdAt).format('YYYY-MM-DD') < firstDayOfMonth
                                        ) {
                                          text1 = 'You can only change the status of actions that were created in the';
                                          text2 = 'CURRENT MONTH'
                                        }
                                          return (
                                          <li className={cn('analytics-li', {'false-positive-anylytics': action?.falsePositive})} key={action?.id}>
                                            <span>{(" " + action?.type)?.replace(/([A-Z])/g, ' $1').trim()?.replace(/^./, str => str.toUpperCase())}</span>
                                            <span>
                                              <div>
                                                {moment(action?.createdAt).utc().format('MMM DD, YYYY')}<br />
                                                <small>at {moment(action?.createdAt).utc().format('hh:mm A')}</small>
                                              </div>
                                            </span>
                                            <span>
                                              <label
                                                className={"slider-checkbox"}
                                              >
                                                <input
                                                  type="checkbox"
                                                  className='checkbox-input'
                                                  checked={action?.falsePositive}
                                                  onClick={() => setSelectedActionId(action.id)}
                                                  onChange={() => handleFalsePositiveCheckboxChange(
                                                    action.id, !action?.falsePositive, `${item?.segmentId}_${item?.emailTemplateId}`
                                                  )}
                                                  disabled={falsePositiveButtonDisabled}
                                                />
                                                <CustomizedTooltipFalsePositive
                                                  showFragment={!falsePositiveChangingLoading}
                                                  text1={text1}
                                                  text2={text2}
                                                  component={
                                                    <span className={cn("slider", {
                                                      "slider-checkbox-disabled": falsePositiveButtonDisabled
                                                    })} />
                                                  }
                                                />
                                                {action.id === selectedActionId && falsePositiveChangingLoading &&
                                                  <span className="mini-loader"></span>
                                                }
                                              </label>
                                            </span>
                                          </li>
                                        )
                                      })}
                                  </ul>
                                </td>
                              </>
                            }
                          </tr>
                        )})
                    }
                  </tbody>
                </table>
              : <div className='analytics-users-table--loading'>
                  <Loader custom={true} />
                </div>
            : !secondaryLoading &&
                <div>
                  <p className="history-description">
                    There are no mailing history yet.
                  </p>
                </div>
          }
        </>
      </div>
      {!!mailingHistory.length && !additionalLoading &&
        <Pagination
          siblingCount={2}
          currentPage={page}
          totalCount={count}
          pageSize={itemsOnPage}
          onPageChange={handlePageChange}
        />
      }
    </div>
  )
});
