import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { Loader } from '../../components/Loader/Loader';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { AuthContext } from '../../context/AuthContext';
import { useTitle } from '../../hooks/title.hook';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { PhishReportsOption } from '../../components/ReactSelect/ReactSelectOption';
import { ReactComponent as ConfigSettings } from '../../images/icons/user-settings.svg';
import { ReactComponent as Check } from '../../images/icons/Check-red-small.svg';
import { CustomizedTooltipInfo } from '../../components/Tooltips/Tooltip';
import { customStyles } from '../../styles/customStyles';
import { useCrypto } from '../../hooks/crypto.hook';
import './ReportedPhish.css';

export const ReportedPhish = () => {
  useTitle("PhishFirewall | Reported phish")

  const { loading, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { encryptData } = useCrypto();
  const { token, phishReports, user, showToastMessage, fetchUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [selectedReport, setSelectedReport] = useState(null);
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [eachPhishReportTicketNotification, setEachPhishReportTicketNotification] = useState(user.eachPhishReportTicketNotification);

  useEffect(() => {
    setEachPhishReportTicketNotification(user.eachPhishReportTicketNotification)
  }, [user, modalIsOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const handleReportChange = (report) => {
    setSelectedReport(report);
  };

  useEffect(() => {
    !selectedReport
      ? navigate(window.location.pathname, { state: null })
      : navigate(
          `/back_office/phish_reports/${selectedReport?.status?.replace('not-phish', 'not_phish')}`,
          { state: selectedReport }
        );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  const updateEachPhishReportTicketNotification = async (eachPhishReportTicketNotification) => {
    try {
      if (!token) return;
      const data = encryptData({ eachPhishReportTicketNotification });
      const response = await requestWithSecondaryLoading('/back_office/api/phish_reports/user_update', 'PATCH', { data }, {
        Authorization: `Bearer ${token}`
      });

      fetchUser();
      showToastMessage(response.error, response.message);
      closeModal();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="reports-main">
        <div className='reports-main-title-container'>
          <h2 className="reports-main-title">
            Reported phish
          </h2>

          <button
            className='user-configuration-settings'
            onClick={openModal}
          >
            <ConfigSettings />
            Additional configuration
          </button>
        </div>

        {!loading
          ? <>
              <div className='reports-main-container'>
                <nav className="reports-nav">
                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/new"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    New ({phishReports?.new || 0})
                  </NavLink>

                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/phish"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    Phish ({phishReports?.phish || 0})
                  </NavLink>

                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/not_phish"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    Not phish ({phishReports?.notPhish || 0})
                  </NavLink>
                  
                  <NavLink
                    onClick={() => setSelectedReport(null)}
                    to="/back_office/phish_reports/spam"
                    className={({ isActive }) => cn('reports-item',
                      { 'reports-item--active': isActive })}
                  >
                    Spam ({phishReports?.spam || 0})
                  </NavLink>
                </nav>

                <div className={cn('phish-reports-select', {
                  'phish-reports-select--disabled': loading || !phishReports?.userStoriesSelect?.length
                })}>
                  <label className='userslist-select-label'>
                    <ReactSelect
                      classNames={'select-phish-reports'}
                      options={phishReports?.userStoriesSelect || []}
                      optionComponent={ PhishReportsOption }
                      placeholder={'Search a report...'}
                      value={selectedReport}
                      onChange={handleReportChange}
                      isSearchable={true}
                      isDisabled={loading || !phishReports?.userStoriesSelect?.length}
                      isClearable={true}
                      optionHeight={82}
                      rows={5}
                    />
                  </label>
                </div>
              </div>

              <Outlet context={selectedReport} />
            </>
          : <Loader />}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='reports-modal-container'>
          <button onClick={closeModal} className="modal-close"></button>
          <h2 className="modal-title">Additional configuration</h2>
            <div className="card-content-all-users">
              <label
                className={"slider-checkbox"}
              >
                <input
                  type="checkbox"
                  className='checkbox-input'
                  checked={eachPhishReportTicketNotification}
                  onChange={() => setEachPhishReportTicketNotification(prev => !prev)}
                />
                <span className={"slider"} />
              </label>
              <label className='reports-label'>Notify You about each phish report created?</label>
              <CustomizedTooltipInfo
                text={
                  `Send notification emails for each new phish report created`
                }
              />
            </div>

            <button
              type='button'
              className='button'
              onClick={() => updateEachPhishReportTicketNotification(eachPhishReportTicketNotification)}
              disabled={secondaryLoading || user.eachPhishReportTicketNotification === eachPhishReportTicketNotification}
            >
              <Check />
              Save changes
            </button>
        </div>
      </Modal>
  </>
  )
}
